import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

export default function Article () {
            
    return (
     <div>
        <Navbar/>
        <div className='container'>
            <div className="header__article">
            <p>BATTERIE ASUS </p>
            </div>
          <div className="container-contents">
          <div className="side">
            <div className="filtre">
              <h1>FAMILLE</h1>
                <p>ASUS CHROMEBOOK</p>
              <hr/>
              <p>CHROMEBOOK</p>
              <hr/>
              <p>MEMOPAD</p>
              <hr/>
              <p>NEXUS</p>
              <hr/>
              <p>ZENBOOK</p>
              <hr/>
              <p>ZENPAD</p>
              <hr/>
            </div>

          </div>
          <div className="content">
          <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 

                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 

                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 

                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 

                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 

                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 
                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 
                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 
                    <div className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src="https://www.cdiscount.com/pdt2/0/0/8/1/300x300/tec5953166148008/rw/td-r-ecran-iphone-x-noir-vitre-tactile-lcd-retina.jpg" alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>Apple</p>
                               <h5 className='product__categorie'>Smartphone</h5>
                               <p className='product__desc'>Ecran Tactile OLED pour iPhone X Vitre De Remplacement 3D Touche Ecran</p>
                               <p className='product__price'>15 000 FCFA</p>
                               <p className="reference">Réf:ECMHDEDIPACCHCB3</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to='/detail'><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 
          </div>
        </div>
        </div>
     </div>
    );
}

