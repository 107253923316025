import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { coffee } from '@fortawesome/free-brands-svg-icons'
export default function  Footer () {
            
return (
    <div>
          <div className='footer'>
          <div className='right'>
            <p>Copyright © 2022,DOCTEUR PIECES </p>
            </div>
            

           {/* <div className='name--developer'>
            <p>Developed by Doumbia Fode (0777880082)</p>
           </div> */}
      </div>
      <div className='navbar--header'>
                <p><i class="fa-solid fa-location-dot"></i> Cocody Angre 7eme Tranche</p>
                <p><i class="fa-solid fa-envelope"></i> docteur@piece.com</p>
                {/* <p><i className="fa-solid fa-user"></i> Login</p> */}
                <p><i class="fa-brands fa-whatsapp"></i> +225 07 77 88 00 82 </p>
                <p><i class="fa-brands fa-facebook"></i> Facebook</p>
                <p><i class="fa-brands fa-instagram"></i> Instagram</p>
                {/* <p><i class="fa-solid fa-cart-arrow-down"></i> Mon Panier</p> */}
                
        </div>
    </div>

    );
}

