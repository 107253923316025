import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"

export default function NavLogin ({nav,setNav}) {

  
//   const showNav = ()=>{
//     setNav(true);
//   }
  const closeNav = ()=>{
    setNav(false)
  }


  return (
    <div>
            <div className={nav ? "log__part open__log__part" : "log__part"}>
                                <div className="btn--close--part">
                                    <i className="fa-solid fa-xmark" onClick={closeNav}></i>
                                </div>

                            <div className="col-3 col-md-12">
                                 <Link to='/loginPage'><button className="btn__log"> Se connecter</button></Link>
                            </div>

                            <div className="user--part">
                            <Link to='/loginPage' className='na'> <i class="fa-solid fa-user"></i> Votre Compte</Link>
                            </div>

                            <div className="user--part">
                            <Link to='/cart' className='na'><i class="fa-solid fa-cart-shopping"></i> Votre panier</Link>
                            </div>
             </div>
        
        </div>
  )
}