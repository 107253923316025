const initialState = {
    carts: localStorage.getItem("carts")
    ? JSON.parse(localStorage.getItem("carts"))
    : [],
    quantity:0
  }
  const storeInLocalStorage = (data) => {
    localStorage.setItem('cart', JSON.stringify(data));
}

  export function cartReducer (state = initialState, action) {
    switch (action.type) {
      case "ADD_TO_CART":{
        const item = state.carts.find(cart => cart.id === action.payload,
        );
        return {
          ...state,
          carts: [...state.carts, action.payload],
          totalPrice: state.totalPrice + action.payload.price,
        };
        
      }
      case 'INCREASE_QUANTITY':
      return {
        ...state,
        quantity: action.quantity + 1
      };
    case 'DECREASE_QUANTITY':
      if (state.quantity > 0) {
        return {
          ...state,
          quantity: action.quantity - 1
        };
      }
        case "DELETE-ARTICLE":{
          let newState = [...state.carts] 
          let index = newState.findIndex(cart => cart._id === action.payload);
          newState.splice(index, 1);
          return {...state, carts: [...newState]};
        }
  
      // case "GET-USER-CART":{
      //   return {...state,carts: [...state.carts, action.payload]}
      // }
      
      default:
        return state
    }
  }