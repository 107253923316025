import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { AddArticle, addToCart, getCatArticlesSmart, getCateSmart } from "../Redux/actions";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ProductCart from "../components/ProductCart";
import LogPage from "../components/LogPage";



export default function Smartphone () {
    const dispatch = useDispatch();
    // const [brand,setBrand]=useState("")

    let slug = useParams().slug;
    const categoryArticles = useSelector(state=>state.categoryReducer.categoryArticles);
    const keys = ["title","brand","categorie","reference"];
    const [articles,setCat]=useState(categoryArticles);
    const [brand,setBrand]=useState("");
    const [search,setSearch]=useState("");

    const AddArticle = (id) => {
        dispatch(addToCart(id))
      }


    const handleChange = (event)=>{
            setSearch(event.target.value)
        }

    const filterResult = (catItem)=>{
      const filterData = categoryArticles.filter((categoryArticle) =>  categoryArticle.categorie === catItem )
          setCat(filterData);
          console.log(filterData)
  }

    useEffect(() => {
        fetch('https://back-doc.onrender.com/products')
        .then((res)=>res.json())
        .then((categoryArticles)=>{dispatch(getCatArticlesSmart(categoryArticles))
        })
        .catch(e => { console.log(e)})
        }, [articles])


        

    return (
        <div>
            <Navbar/>
            <div className="container">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Smartphone</li>
            </ol>
            </nav>
            <p className="container--header">Nos pièces détachées pour smartphones </p>
            </div>
            <ProductCart/>
        <div className="container-contents">
          <div className="sidebar">
          <h3>{slug}</h3>
          <div className="filter--container">
            <h4>Filter par categorie</h4>
            <h6>{articles.length} produit(s)</h6>

            {/* <div className="col-3 col-md-12">
              <div class="form-floating mb-3">
              <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" onChange={handleChange}/>
              <label for="floatingInput">Rechercher</label>
            </div>
            </div> */}

            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{setCat(categoryArticles)}}>All</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Montre')}}>Accessoires</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Adaptateur')}}>Adaptateurs</button>         
            </div>

            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Batterie')}}>Batteries</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Câble')}}>Câbles</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Chargeur')}}>Chargeurs</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Connecteur')}}>Connecteurs</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Coque')}}>Coques</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Ecran')}}>Ecrans</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Ecouteur')}}>Ecouteurs</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Montre')}}>Montres</button>         
            </div>
            
                </div>
            </div>
            

        <div className="part__accessories">
          {articles.map((article) => <div key={article._id}className='product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                           <Link to={`/detail/${article._id}`}><img src={article.image} alt="" /></Link>
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>{article.brand}</p>
                                <h5 className='product__title'>{article.title}</h5>
                               <h5 className='product__categorie'>{article.categorie}</h5>
                               <div className='product__desc'>
                                <p>{article.description}</p>
                               </div>
                               <p className='product__price'>{article.price}</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to={`/detail/${article._id}`}><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'onClick={() => AddArticle(article)}>+</button>
                                </div>  
                        </div>
                    </div> 
                        )}
                    
          </div>
          
          </div>
        <Footer/>
        </div>
    )
}