import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Filter from "../components/Filter";
import Nav from "../components/Nav";
import ProductCart from "../components/ProductCart";
import { getALLLaptop, getCatArticlesSmart, getLaptop } from "../Redux/actions";
import Footer from "./Footer";
import Navbar from "./Navbar";



export default function Laptop(){
  const dispatch = useDispatch();

  let slug = useParams().slug;
  const laptops = useSelector(state=>state.categoryReducer.laptops);
  const [products,setData]=useState(laptops);


  const filterResult = (catItem)=>{
    const filterData = laptops.filter((laptop) =>  laptop.categorie === catItem )
        setData(filterData);
        console.log(filterData)
        }

        useEffect(() => {
                fetch('https://back-doc.onrender.com/laptops')
                .then((res)=>res.json())
                .then((laptops)=>{dispatch(getALLLaptop(laptops))
                })
                .catch(e => { console.log(e)})
                }, [products])
        
          console.log(laptops)

        
    return (
        <div>
               <Navbar/>
            <div className="container">
            <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Laptop</li>
            </ol>
            </nav>
            <ProductCart/>
            <p className="container--header">Nos pièces détachées pour oridnateur portables </p>
            </div>

            <div className="container-contents">
          <div className="sidebar">
          <h3>{slug}</h3>
          <div className="filter--container">
            <h4>Filter par categorie</h4>
            <h6>{products.length} produit(s)</h6>

            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{setData(laptops)}}>All</button>         
            </div>

            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Batterie')}}>Batteries</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Ecran')}}>Ecrans</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Chargeur')}}>Chargeurs</button>         
            </div>
            <div className="col-3 col-md-12">
                    <button className="btn__filter" onClick={()=>{filterResult('Connecteur')}}>Connecteurs</button>         
            </div>
                </div>
            </div>
            

        <div className="part__accessories">
          {products.map((product) => <div key={product._id}className='featured__product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                            <img src={product.image} alt="" />
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>{product.brand}</p>
                                <p className='brand'>{product.title}</p>
                               <h5 className='product__categorie'>{product.categorie}</h5>
                               <p className='product__desc'>{product.description}</p>
                               <p className='product__price'>{product.price}</p>
                               <p className="reference">{product.reference}</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to={`/detail/${product._id}`}><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'>+</button>
                                </div>  
                        </div>
                    </div> 
                        )}
                    
          </div>
          </div>
        <Footer/>
        </div>
    )
}