
export function getCateSmart(product) {
    return {
        type: 'GET-CATEGORIES-SMART',
        payload: product
    }
}
export function getCatArticlesSmart(products) {
    return {
        type: 'GET-CATEGORY-ARTICLES',
        payload: products
    }
}
export function getProduct(article) {
    return {
        type: 'GET-ARTICLE',
        payload: article
    }
}
export function getALLLaptop(laptops) {
  return {
      type: 'GET-ALL-LAPTOP',
      payload: laptops
  }
}
export function getLaptop(laptop) {
  return {
      type: 'GET-LAPTOP',
      payload: laptop
  }
}
export function AddArticle (id) {
    return {
      type: "ADD-ARTICLE",
      payload: {id},
    }
  }

  export function DeleteArticle(id){
    return {
      type: "DELETE-ARTICLE",
      payload: id,
    }
  }

export function getUserCart(cart){
  return {
    type:"GET-USER-CART",
    payload :cart
  }
}

export function getUser(user){
  return {
    type:"GET-USER",
    payload:user
  }
}
export const addToCart= (id)=>{
  return{
      type: 'ADD_TO_CART',
      payload:id
  }
}
//remove item action
export const removeItem=(id)=>{
  return{
      type: 'REMOVE_ITEM',
      id
  }
}
//subtract qt action
export const subtractQuantity=(id)=>{
  return{
      type: 'SUB_QUANTITY',
      id
  }
}
//add qt action
export const addQuantity=(id)=>{
  return{
      type: 'ADD_QUANTITY',
      payload:id
  }
}
export const increaseQuantity =(id)=> ({
  type: 'INCREASE_QUANTITY',
  payload:id
});

export const decreaseQuantity =(quantity)=>({
  type: 'DECREASE_QUANTITY',
  quantity
});

