import React from 'react';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import NavLogin from '../components/NavLogin';

function Navbar () {
  const [nav , setNav] = useState(false);

    const openLog = ()=>{
      setNav(true)
    }


    
    return (
      <div>
        <NavLogin nav={nav} setNav={setNav}/>
      <header>
        <div className='navbar'>
          <div className="navbar--left">
            <div className="navbar--logo">
              <Link to="/"> <img src={`${process.env.PUBLIC_URL}/dp.jpg`} alt=""/></Link>
            </div>
          </div>
          <div className="navbar--right">
            <div className="navbar--right--content">
              <Link className='liste' to="/">Accueil</Link>
              <Link className='liste' to='/laptop'><i className="fa-solid fa-laptop"></i> Ordinateurs Portables</Link>
              <Link className='liste' to='/smartphone'><i className="fa-solid fa-mobile"></i> Smartphone</Link>
              <Link className='liste' to='/identification'><i className="fa-solid fa-magnifying-glass"></i> Trouvez votre pièce</Link>
              <Link className='liste' ><button onClick={openLog} className="btn--connexion"><i className="fa-solid fa-user"></i> Se connecter</button></Link>

              <form>
                <input className="form-control me-2" type="text" id='search' placeholder="Search" />
              </form>
            </div>
               
          </div>
          </div>
    </header>

              </div>
    );
}

export default Navbar;
