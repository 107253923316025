import { combineReducers } from "redux";
import { homeReducer } from './homeReducer';
import { rentHomeReducer } from "./rentHomeReducer";
import {categoryReducer} from "./categoryReducer";
import  {cartReducer}  from "./cartReducer";



export const rootReducer = combineReducers({
    homeReducer : homeReducer,
    rentHomeReducer : rentHomeReducer,
    categoryReducer : categoryReducer,
    cartReducer:cartReducer
})