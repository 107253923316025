import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import 'animate.css';
import Smartphone from './Smartphone';
import Laptop from './Laptop';
import Navbar from './Navbar';
import Footer from './Footer';
import Slider from '../components/Slider';
import { AddArticle, getALLLaptop, getCatArticlesSmart } from '../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import ProductCart from '../components/ProductCart';

export default function Accueil () {
    const dispatch = useDispatch()
  const [display,setDisplay]= useState(false);

  const [cart,setCart]= useState(false);

  const closeCart = ()=> {
    setCart (false)
}
const showCart =()=>{
    setCart (true)
}
const addToCart = (id) => {
    dispatch(AddArticle(id))
  }

  const categoryArticles = useSelector(state=>state.categoryReducer.categoryArticles);
//   const [articles,setCat]=useState(categoryArticles);
  useEffect(() => {
    fetch('http://127.0.0.1:5000/productsgdg')
    .then((res)=>res.json())
    .then((categoryArticles)=>{dispatch(getCatArticlesSmart(categoryArticles))
    })
    .catch(e => { console.log(e)})
    }, [])


    return (
      <div>
         <Navbar setDisplay={setDisplay}/>
            <div className="society">
                <h1 className='container--header'>Nos marques</h1>
              </div>
            <div className="society--details animate__animated animate__lightSpeedInLeft">
                <img src="https://logos-marques.com/wp-content/uploads/2021/03/Apple-logo.png" alt="" />
                <img src="https://rouen-informatique.com/wp-content/uploads/2019/02/Logo-Asus.jpg" alt="" />
                <img src="https://guide-images.cdn.ifixit.com/igi/cDZiwSJVRhEXkKCC.large" alt="" />
                <img src="https://www.f3df.com/wp-content/uploads/2018/02/hp-logo-480x480.png" alt="" />
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSueOGkrxm-15qeNhA5lefWu61i-ivS6iDqpSA3PpNssyu8RwEPxzXLZGnq9qItq2AIVs&usqp=CAU" alt="" />
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0NuTpJ3fKNFdqxJO-pPavhX7tVh6My9fRhOmLwb5Rl7eCx49zu4c4OPBZd6mZungwA8g&usqp=CAU" alt="" />
                <img src="https://phonesdata.com/files/brands/samsung.jpg" alt="" />
                <img src="https://mms.businesswire.com/media/20211114005296/fr/806231/23/Toshiba_logo.jpg" alt="" />
            </div>

                  <Slider/>

          <div className="container">
              <div className='main__part__categories'>
                        <h2 className='title__categorie'>Nos categories</h2>
                        <div className='categories__content'>
                        <div className='categories'>
                            <Link to='/laptop'><p className='cat'>Ordinateur & Informatique</p></Link>
                        </div>
                        <div className='categories'>
                            <Link to='/smartphone'><p className='cat'>Téléphone & Tablettes</p></Link>
                        </div>

                        <div className='categories'>
                            <Link to='/smartphone'><p className='cat'>Accessoires</p></Link>
                        </div>
                        </div>
                </div>
        

            {/* <div className='cart--part' onClick={showCart}>
               <p className='number__product'>2</p>
                <i className="fa-solid fa-cart-shopping"></i>
            </div> */}
            <div className="cart--icon" onClick={showCart}>
                        <i className="fa-solid fa-bag-shopping"></i>
                        <div className="counter"></div>
                    </div>

            {/* <div className={cart ? "cart__container show--cart__container" : "filterbar"}>
                        <div className='cart__container--content'>
                        <div className='btn--close--sidebar'onClick={closeCart}> <i className="fa-solid fa-xmark"></i></div>
                            <h4>Nouvel arrivage</h4>
                            <h4>Top ventes</h4>
                        </div>
                </div> */}
                <ProductCart/>

          <div className='col-12 col-md-12' >
          <h2 className='title__categorie'>Nouveaux arrivages</h2>
            <div className='part__new__product'>
                {categoryArticles.map((categoryArticle) => <div key={categoryArticle._id}className='product__cards'>
                        <div className='featured__product__cards__header'>
                        <p className='sales'>Promo -20%</p>
                           <div className='featured__product__cards__header__images'>
                           <Link to={`/detail/${categoryArticle._id}`}><img src={categoryArticle.image} alt="" /></Link>
                           </div>
                        </div>
                        <div className='featured__product__cards__body'>
                                <p className='brand'>{categoryArticle.brand}</p>
                                <h5 className='product__title'>{categoryArticle.title}</h5>
                               <h5 className='product__categorie'>{categoryArticle.categorie}</h5>
                               <div className='product__desc'>
                                <p>{categoryArticle.description}</p>
                               </div>
                               <p className='product__price'>{categoryArticle.price}</p>
                                <p className="info">2 en stock</p>
                               <div className='button--block'>
                               <Link className='link__btn' to={`/detail/${categoryArticle._id}`}><button className='btn__buy'>Acheter</button></Link>
                               <button className='btn__add'onClick={() => addToCart(categoryArticle)}>+</button>
                                </div>  
                        </div>
                    </div> 
                        )}
            </div>
          </div>


          </div>

        <div className='box'>
        <h2 className='finding'>Trouver une pièce d'ordinateur portable en 1 clic</h2>
               <Link to='/identification'className='liste'><p>En savoir plus</p></Link>
        </div>
          {/* <div className='part__newsletter'>
              <div className='part__newsletter__first'>
                  <h3>Inscrivez vous à notre Newsletter</h3>
              </div>
              <div className='part__newsletter__second'>
                  <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                  <label for="floatingInput">Email address</label>
                </div>
                <button className='btn__newsletter'>Envoyer</button>
              </div>
          </div> */}

          <div className="form--part">
                <div className='form--part--content'>
                <p>Vous n’avez pas trouvé la pièce dont vous avez besoin sur notre site ? Remplissez le formulaire et nous chercherons cette pièce pour vous.</p>
                <Link to='/formulaire'> <p>Formulaire de demande de pièce</p></Link>
                </div>
          </div>
          <Footer/>
        </div>

    );
}

