const initialState = {
    categories: [],
    categoryArticles: [],
    article:{},
    laptops: [],
    laptop: {},
}

export function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case "GET-CATEGORIES-SMART": {
            return {
                ...state,categories : action.payload
            }
        }
        case "GET-CATEGORY-ARTICLES": {
            return {
                ...state,categoryArticles : action.payload
            }
        }
        case "GET-ARTICLE": {
            return {
                ...state,article : action.payload
            }
        }
        case "GET-ALL-LAPTOP": {
            return {
                ...state,laptops: action.payload
            }
        }
        case "GET-LAPTOP": {
            return {
                ...state,laptop: action.payload
            }
        }
        default: {
            return state
        }
    }
  
}