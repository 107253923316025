import './App.css';
import {Routes,Route} from 'react-router-dom';
import Contact from './Pages/Contact';
import Detail from './Pages/Detail';
import 'animate.css';
import Identification from './Pages/Identification';
import Formulaire from './Pages/Formulaire';
import Article from './Pages/Article';
import Accueil from './Pages/Accueil';
import Laptop from './Pages/Laptop';
import Smartphone from './Pages/Smartphone';
import Accessoires from './Pages/Accessoires';
import Commandes from './Pages/Commandes';
import LogPage from './components/LogPage';



function App() {
  return (
    <Routes>
    <Route path="/" element={<Accueil/>}/>
    <Route path='/contact' element={<Contact/>} />
    <Route path='/identification' element={<Identification/>} />
    <Route path='/formulaire' element={<Formulaire/>} />
    <Route path='/detail' element={<Detail/>} />
    <Route path='/detail/:id' element={<Detail/>} />
    <Route path='/article' element={<Article/>} />
    <Route path='/laptop' element={<Laptop/>}/>
    <Route path='/smartphone' element={<Smartphone/>}/>
    <Route path='/accessoires' element={<Accessoires/>}/>
    <Route path='/category/:slug' element={<Smartphone/>}/>
    <Route path='/cart' element={<Commandes/>}/>
    <Route path='/loginPage' element={<LogPage/>}/>

  </Routes>
  );
}

export default App;
