import React, { useEffect } from 'react';
import Navbar from './Navbar';
import {Link, useParams} from 'react-router-dom';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { AddArticle, getLaptop, getProduct } from '../Redux/actions';
import ProductCart from '../components/ProductCart';

export default function Detail () {

  const dispatch = useDispatch();
  const article = useSelector(state=>state.categoryReducer.article);
  const laptop = useSelector(state=>state.categoryReducer.laptop);
  let id = useParams().id
  const detail = article ? laptop : article ;

  useEffect(() => {
    fetch(`https://back-doc.onrender.com/products/${id}`)
    .then((res)=>res.json())
    .then((article)=>{dispatch(getProduct(article))
    })
    }, [id])

    // useEffect(() => {
    //   fetch(`http://127.0.0.1:5000/laptops/${id}`)
    //   .then((res)=>res.json())
    //   .then((laptop)=>{dispatch(getLaptop(laptop))
    //   })
    //   }, [id])

    const addToCart = (id) => {
      dispatch(AddArticle(id))
    }

            
    return (
     <div>
        <Navbar/>
        <ProductCart/>
        <div className='container'>
        <div className="container--header">
            {/* <h4 className="name--article">Iphone 14 Pro Max</h4> */}
          </div>
              <div className="container--article">
                <div className="container--article--left--part">
                  <div className="container--article--left--part--content">
                    <div className="container--article--left--part--content--image">  
                      <img src={article.image} alt="" />
                    </div> 
                  </div> 
                
                </div>

                <div className="container--article--right--part">
                        <p className='label'>{article.title}</p>
                        <h4 className="name--article">{article.description}</h4>
                        <p className='label'>Marque : {article.brand}</p>
                        <p className='reference--article'>Référence: {article.reference}</p>
                        <p className='rating'>3 ratings</p>
                        <p className='availability'>En stock</p>
                        <p className='warranty'> Garantie: 1 mois </p>
                        <p className="price"> {article.price}</p>                
                          <button className='btn--add--cart' onClick={() => addToCart(article)}> <i className="fa-sharp fa-solid fa-cart-plus"></i> Acheter</button>
                    </div>         
              </div>
        </div>
        <Footer/>
     </div>
    );
}

